/*
 * Globals
 */


/* Links */

a,
a:focus,
a:hover {
    color: #fff;
}

*,
::after,
::before {
    box-sizing: border-box;
}

user agent stylesheet body {
    display: block;
    margin: 8px;
}

:root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #007bff;
    --secondary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: "Raleway", sans-serif;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}

*,
::after,
::before {
    box-sizing: border-box;
}

*,
::after,
::before {
    box-sizing: border-box;
}


/* Custom default button */

.btn-default {
    color: #fff;
    text-shadow: none;
    /* Prevent inheritence from `body` */
    background-color: transparent;
    border: 2px solid #fff;
    border-radius: 20px;
    padding: 0.5rem 2rem;
}

.btn-default:hover,
.btn-default:focus {
    background-color: rgba(255, 255, 255, 0.3);
}


/*
 * Base structure
 */

html,
body {
    height: 100%;
}

body {
    background: url("../assets/bg.jpg") no-repeat center center fixed;
    background-size: cover;
    color: #fff;
    text-align: center;
    font-family: "Raleway", sans-serif;
    font-size: 13px;
}

.h3,
h3 {
    font-size: 1.75rem;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}

.lead {
    font-size: 1.25rem;
    font-weight: 300;
}


/* Extra markup and styles for table-esque vertical and horizontal centering */

.site-wrapper {
    display: table;
    width: 100%;
    height: 100%;
    /* For at least Firefox */
    min-height: 100%;
    /* background: rgba(48, 53, 70, 0.5);
  box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.5); */
}

.site-wrapper-inner {
    display: table-cell;
    vertical-align: top;
}

.cover-container {
    margin-right: auto;
    margin-left: auto;
}

.MainGrid {
    border-radius: 10px;
    box-shadow: 0px 0px 10px #0000001a;
    padding: 20px;
}


/* Padding for spacing */

.inner {
    padding: 30px;
    /* display: flex;
  justify-content: space-between; */
}

.MuiFormLabel-root {
    font-family: "Raleway" !important;
}

.MuiInputBase-input {
    font-size: 15px !important;
    border-radius: 10px !important;
    font-family: "Raleway" !important;
}

.blink span {
    animation: blink 2s linear infinite;
}

@keyframes blink {
    0% {
        opacity: 0;
    }
    50% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    }
}

.badge {
    font-family: 'Raleway' !important;
    background-color: blue;
    border-radius: 20px;
    color: white;
    padding: 5px 15px 5px 15px;
    margin-right: 10px;
}

.tag-cloud {
    font-family: 'Raleway' !important;
}

@keyframes blinker {
    50% {
        opacity: 0.0;
    }
}

.MuiDialog-paperWidthMd {
    width: 900px;
}

.MuiButton-containedPrimary {
    background-color: #2091f7 !important;
}


/*
 * Header
 */

.masthead-brand {
    margin-top: 10px;
    margin-bottom: 10px;
}

.nav-masthead {
    text-align: center;
    display: block;
}

.nav-masthead .nav-link {
    display: inline-block;
}


/* .MuiPaper-root {
  background-image: url("../assets/bg.jpg");
} */


/*
 * Cover
 */

.cover {
    padding: 100px 20px;
    text-align: center;
}

.cover .btn-notify {
    padding: 10px 60px;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 40px;
}

.cover-heading {
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 10px;
    font-size: 2rem;
    margin-bottom: 5rem;
}

.cover-copy {
    max-width: 500px;
    margin: 0 auto 3rem;
}

.cover-company {
    text-align: end;
    margin: 0 25px 3rem;
}


/*
 * Footer
 */

.mastfoot {
    color: #999;
    /* IE8 proofing */
    color: rgba(255, 255, 255, 0.5);
}


/*
 * Subscribe modal box
 */

#subscribeModal .modal-content {
    background-color: #303546;
    color: #fff;
    text-align: left;
}

#subscribeModal .modal-header,
#subscribeModal .modal-footer {
    border: 0;
}

#subscribeModal .close {
    color: #fff;
}

#subscribeModal .form-control {
    margin-top: 1rem;
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
}

#subscribeModal .form-control:focus {
    border-color: #49506a;
}

.register-main {
    text-align: -webkit-center;
}

.MuiTypography-h6 {
    font-size: 30px !important;
    font-weight: 600 !important;
    font-family: "Raleway" !important;
    color: #00104c;
}

.card {
    background: #fff;
    box-shadow: 0 4px 8px 0 rgba(255, 0, 0, 0.2);
    transition: 0.3s;
    width: 40%;
    padding: 20px;
    border-radius: 5px;
}

.container {
    padding: 2px 16px;
}

.errorMsg {
    font-family: "Raleway", sans-serif;
    font-weight: 400;
    position: "absolute";
    top: 10;
    right: 10;
    white-space: "nowrap";
    overflow: "ellipsis";
    color: rgb(255, 0, 0);
    font-size: 15;
}

.formControl {
    display: "flex";
    width: "100%";
    flex-wrap: "wrap";
    margin: 1%;
}

.textfield {
    border-radius: 20;
}

.NotesAttachments {
    /* width: 100%; */
    display: flex;
    bottom: 60px;
    position: inherit;
    justify-content: flex-start;
}

.NotesAttachments div {
    width: 150px;
    height: 150px;
    border-radius: 10px;
    box-shadow: 0px 0px 5px #0000001a;
    display: flex;
    margin-right: 15px;
    margin-left: 10px;
    justify-content: center;
    align-items: center;
    position: relative;
}

.NotesAttachments div img {
    height: 100px;
    width: 100px;
}

.NotesAttachments div span {
    font-weight: bold;
    position: absolute;
    top: 5px;
    right: 10px;
    z-index: 100;
    cursor: pointer;
}

.searchcompany input[type="text"] {
    width: 200px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    background-color: white;
    background-image: url("../assets/search.png");
    background-position: 10px 7px;
    background-repeat: no-repeat;
    padding: 6px 20px 6px 40px;
    -webkit-transition: width 0.4s ease-in-out;
    transition: width 0.4s ease-in-out;
    /* padding: 5px; */
    margin-bottom: 10px;
}

.searchcompany input[type="text"]:focus {
    width: 100%;
    border: 1px solid #28a745 !important;
}


/*
 * Affix and center
 */

@media (min-width: 768px) {
    /* Pull out the header and footer */
    .masthead {
        position: fixed;
        top: 0;
    }
    .mastfoot {
        position: fixed;
        bottom: 0;
    }
    /* Start the vertical centering */
    .site-wrapper-inner {
        vertical-align: middle;
        text-align: end;
    }
    /* Handle the widths */
    .masthead,
    .mastfoot,
    .cover-container {
        width: 100%;
        /* Must be percentage or pixels for horizontal alignment */
    }
    .masthead-brand {
        float: left;
    }
    .nav-masthead {
        float: right;
    }
    .cover-heading {
        font-size: 3.4rem;
        letter-spacing: 15px;
    }
}

@media (min-width: 992px) {
    .masthead,
    .mastfoot,
    .cover-container {
        width: 100%;
    }
}